import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';
import { useAuthUser } from 'react-auth-kit';
import { axiosRequestGet, axiosRequest, axiosRequestPut } from '../../network/axiosRequest';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'; // Import SweetAlert2
import '../../assets/template/css/style.css';
import Header from '../../components/header';
import Loader from '../../components/loader';
import { Label } from 'recharts';
import TypingEffect from '../../components/typingeffect';

interface Template {
  id: number;
  title: string;
  subtitle: string;
  form_fields: number; // The ID of the form field
  gptprompt: string;
  // Add other fields as needed
}

interface UserAvatar {
  id: number;
  avatar_name: string;
  user_id: number;
  form_field_id: number;
  status: number;
  created_at_avatar: string;
  updated_at_avatar: string;
  user_response: {
    [key: string]: string;
  };
}

interface FormField {
  class: string;
  inputType: string;
  lenMsg: string;
  name: string;
  optional: boolean;
  options: { key: string; select_value: string }[];
  placeholder: string;
  requiredMsg: string;
  subtitle: string;
  tag: string;
  title: string;
  value: string;
}

interface FieldData {
  field: FormField[];
  id: number;
  name: string;
  status: number;
}

const TemplateDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [template, setTemplate] = useState<Template | null>(null);
  const [userAvatars, setUserAvatars] = useState<UserAvatar[]>([]);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [reaction, setReaction] = useState<number | null>(null);
  const [formFields, setFormFields] = useState<FieldData | null>(null);
  const [storedAvatarId, setStoredAvatarId] = useState<number | null>(null);
  const [storedTemplateId, setStoredTemplateId] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [gptResponse, setGptResponse] = useState('');
  const [historyId, setHistoryId] = useState('');
  const [thumbsUp, setThumbsUp] = useState(false);
  const [thumbsDown, setThumbsDown] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showAdditionalPromptForm, setShowAdditionalPromptForm] = useState(false);
  const [additionalPrompt, setAdditionalPrompt] = useState('');
  const [selectedRadio, setSelectedRadio] = useState<string | null>(null);
  const [forceRender, setForceRender] = useState(false);
  const [formData, setFormData] = useState<Record<string, any>>({});

  const { register, handleSubmit, formState: { errors } } = useForm();
  const auth = useAuthUser();
  const user_id = auth()?.user_id; 
  const navigate = useNavigate();

  // Extract the token and store it in a variable
  const token = auth()?.token;

  useEffect(() => {
    if (!id || !token) {
      return; // Do nothing if id or token is missing
    }

    const fetchData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        // Fetch template details
        const responseTemplate = await axiosRequestGet(`/admin/template/${id}`, headers);
        setTemplate(responseTemplate);

        // Fetch user avatars
        const responseAvatars = await axiosRequestGet('/admin/user/user_avatar', headers);
        setUserAvatars(responseAvatars);

        // Fetch form fields based on form_field_id from the template
        const formFieldId = responseTemplate.form_fields; // Get the form_field_id from the template
        const responseFormFields = await axiosRequestGet(`/user/form_field/${formFieldId}`, headers);
        setFormFields(responseFormFields);
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Failed to fetch data:', error.message);
          setError('Failed to load data.');
        } else {
          console.error('An unknown error occurred:', error);
          setError('An unknown error occurred.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, token]);

  const fetchAvatarDetails = async (avatarId: number) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const response = await axiosRequestGet(`/admin/user_avatar_by_avatar_id/${avatarId}`, headers);
      return response;
    } catch (error) {
      console.error('Failed to fetch Ideal Client details:', error);
      return null;
    }
  };


  const onSubmit = async (data: Record<string, any>) => {
    try {
      setLoading(true);
  
      // Check if id is defined before using it
      if (typeof id === 'undefined') {
        throw new Error('Template ID is undefined');
      }
  
      // Convert id to number
      const templateId = Number(id);
      if (isNaN(templateId)) {
        throw new Error('Invalid template ID');
      }
      setStoredTemplateId(templateId); // Store the template ID
  
      // Parse avatar ID and check if it is valid
      const selectedAvatarId = parseInt(data.avatar, 10);
      if (isNaN(selectedAvatarId)) {
        throw new Error('Invalid Ideal Client ID');
      }
      setStoredAvatarId(selectedAvatarId); // Store the avatar ID
  
      // Fetch avatar details
      const avatarDetails = await fetchAvatarDetails(selectedAvatarId);
      const userResponse = avatarDetails ? avatarDetails.user_response : {};
  
      // Check if template and gptPrompt are available
      if (!template || !template.gptprompt) {
        throw new Error('GPT prompt not found');
      }
      let gptPrompt = template.gptprompt;
  
      // Store the form data in state for later use
      setFormData(data); // Store the entire form data
  
      gptPrompt = gptPrompt.replace(/{(.*?)}/g, (match, fieldName) => {
        // First check in the userResponse
        if (userResponse[fieldName] !== undefined) {
          return userResponse[fieldName];
        }
  
        // If not found, check in formFields
        if (formFields && formFields.field) {
          for (const field of formFields.field) {
            if (field.name === fieldName) { // Match the field name with the placeholder
              const fieldValue = data[field.name];
              if (fieldValue !== undefined) {
                return fieldValue;
              }
            }
          }
        }
  
        // Fallback if no value is found
        return 'No data provided';
      });
  
      // Build result string
      let resultString = `${gptPrompt}\n\n`;
      console.log(resultString);
  
      // Make API requests
      const headers = {
        Authorization: `Bearer ${auth()?.token}`,
        'Content-Type': 'application/json',
      };
  
      const gptResponse = await axiosRequest('/chatgpt/analyze', { result_string: resultString, user_id: user_id, template_id: templateId }, headers);
      const historyResponse = await axiosRequest('/admin/userAvatarHistory/create', {
        avatar_id: selectedAvatarId,
        template_id: templateId,
        result_string: gptResponse.response,
        prompt_generated: resultString,
        total_fee: gptResponse.total_fee,
      }, {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      });
  
      console.log('History created:', historyResponse);
      setGptResponse(gptResponse.response); // Set the GPT response in state
      setLoading(false);
  
      const { history_id } = historyResponse;
      setHistoryId(history_id);
      console.log('History ID:', history_id);
  
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.status === 403) {
        Swal.fire({
          icon: 'error',
          title: 'Exceeded limit',
          text: 'You have exceeded the maximum number of credits to generate Templates, please contact admin or upgrade your plan.',
          customClass: {
            confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          },
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message || 'An unexpected error occurred. Please try again.',
          customClass: {
            confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          },
        });
      }
    }
  };
  
  // Function to handle additional prompt submission
  const handleAdditionalPromptSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      if (storedAvatarId === null || storedTemplateId === null) {
        throw new Error('Stored data is missing');
      }
  
      const selectedAvatarId = storedAvatarId;
      const templateId = storedTemplateId;
  
      const avatarDetails = await fetchAvatarDetails(selectedAvatarId);
      const userResponse = avatarDetails ? avatarDetails.user_response : {};
  
      if (!template || !template.gptprompt) {
        throw new Error('GPT prompt not found');
      }
  
      let gptPrompt = template.gptprompt;
  
      // Use the stored formData state to generate the prompt
      gptPrompt = gptPrompt.replace(/{(.*?)}/g, (match, fieldName) => {
        // Check if the key exists in userResponse
        if (userResponse.hasOwnProperty(fieldName)) {
          return userResponse[fieldName] || 'No response';
        }
  
        // If not found in userResponse, get the value from the stored formData
        const fieldValue = formData[fieldName] || 'No response';
        console.log(`Field value from stored formData: ${fieldName} -> ${fieldValue}`);
        return fieldValue;
      });
  
      let resultString = `${gptPrompt}\n\n`;
  
      if (additionalPrompt) {
        resultString += `\nAdditional Prompt:\n${additionalPrompt}\n`;
      }
  
      console.log(resultString);
  
      const headers = {
        'Content-Type': 'application/json',
      };
      const gptResponse = await axiosRequest('/chatgpt/analyze', { result_string: resultString,  user_id: user_id,template_id: templateId  }, headers);
      const historyResponse = await axiosRequest('/admin/userAvatarHistory/create', {
        avatar_id: selectedAvatarId,
        template_id: templateId,
        result_string: gptResponse.response,
        prompt_generated: resultString,
        total_fee: gptResponse.total_fee
      }, {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      });
      
      console.log('History created:', historyResponse);
      setGptResponse(gptResponse.response); // Set the GPT response in state
      setLoading(false);
  
      const { history_id } = historyResponse;
      setHistoryId(history_id);
      console.log('History ID:', history_id);
  
      setShowAdditionalPromptForm(false);
      setAdditionalPrompt('');
  
    } catch (error: any) {
      console.error('Error submitting additional prompt:', error);
      setLoading(false);
    }
  };
  

const updateStatus = async (status: number) => {
  try {
    if (!historyId) return;

    await axiosRequestPut(`/admin/userAvatarHistory/update_status/${historyId}`, {
      status,
    }, {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    const message = status === 0 ? 'User successfully activated.' : 'User successfully deactivated.';
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: message,
      customClass: {
        confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
      },
    });
  } catch (error) {
    console.error('Error updating status:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to update status.',
      customClass: {
        confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
      },
    });
  }
};

const handleSave = () => updateStatus(0);
const handleDiscard = async () => {
  await updateStatus(1);
  setGptResponse('');   // Clear GPT response
  setHistoryId('');     // Optional: Reset the history ID if needed
  setForceRender(!forceRender);  // Force a re-render
};


const reactToHistory = async (reactionType: number) => {
  try {
    if (!token) return;

    await axiosRequest(`/admin/userAvatarHistory/react/${historyId}`, {
      reaction: reactionType,
    }, {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    setReaction(reactionType);
  } catch (error) {
    console.error(`Error reacting to history: ${error}`);
  }
};


  const handleThumbsUp = () => {
    if (!gptResponse) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No output to like!',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4', // Custom class for the confirm button
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'    // Custom class for the cancel button
        }
      });
      return;
    }
    if (reaction !== 1) {
          reactToHistory(1);
        }
    setThumbsUp((prev) => !prev);
    if (thumbsDown) setThumbsDown(false); // Prevent both being selected
  };

  const handleThumbsDown = () => {
    if (!gptResponse) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No output to dislike!',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4', // Custom class for the confirm button
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'    // Custom class for the cancel button
        }
      });
      return;
    }
    if (reaction !== 2) {
          reactToHistory(2);
    }
    setThumbsDown((prev) => !prev);
    if (thumbsUp) setThumbsUp(false); // Prevent both being selected
  };
  
    // Function to handle copying the GPT response to the clipboard
    const handleCopy = () => {
      if (!gptResponse) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No output to copy!',
          customClass: {
            confirmButton: 'btn btn-primary primary-filled-button py-2 px-4', // Custom class for the confirm button
            cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'    // Custom class for the cancel button
          }
        });
        return;
      }
  
      // Copy the response text to the clipboard
      navigator.clipboard.writeText(gptResponse).then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Copied!',
          text: 'The output has been copied to your clipboard.',
          customClass: {
            confirmButton: 'btn btn-primary primary-filled-button py-2 px-4', // Custom class for the confirm button
            cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'    // Custom class for the cancel button
          }
        });
      }).catch((error) => {
        console.error('Failed to copy text:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to copy the output. Please try again.',
          customClass: {
            confirmButton: 'btn btn-primary primary-filled-button py-2 px-4', // Custom class for the confirm button
            cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'    // Custom class for the cancel button
          }
        });
      });
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedRadio(event.target.value);
    };
 

  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Copy Hub', url: '/copy-hub' },
    { label: 'Copy Hub Output'},
  ];

  return (
  <section className="main-right-wrapper">
     <Header pageTitle="Copy Hub" breadcrumbs={breadcrumbs} enable={true} />
    <div className="main-right-content">
      <div className="container-fluid">
        <div className="row wlcom_bx mb-3 d-md-none d-block">
          <div className="col-12">
            <div className="page-title-box">
              <h2 className="page-title">Copy Hub</h2>
              <div className="breadcrumb">
                  {breadcrumbs.map((breadcrumb:any, index:any) => (
                    <span key={index}>
                      {breadcrumb.url ? (
                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                    </span>
                  ))}
                </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="p-3 bg-light rounded mb-4">
            <h5 className="text-center">
                    {template?.title ? `${template.title}` : "Additional Prompt"}
                  </h5>
              <div className="bg-white border rounded p-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group mb-3">
                    <label className="font15 mb-0 font500">Name of This Ideal Client*</label>
                    <p className="mb-2 font13 font-muted font300">e.g. Caleb / Jia Li / Jessica (This will act as your reference when it comes to using the copy templates)</p>
                    <select 
                      aria-placeholder="Choose an Ideal Client" 
                      className="form-select"
                      {...register('avatar', { required: 'Please select an Ideal Client' })}
                      defaultValue="" // Set the default value to match your initial state
                    >
                      <option value="" disabled>
                        Choose an Ideal Client
                      </option>
                      {userAvatars.length > 0 ? (
                        userAvatars.map((avatar) => (
                          <option key={avatar.id} value={avatar.id}>
                            {avatar.avatar_name}
                          </option>
                        ))
                      ) : (
                        <option value="">No Ideal Clients available</option>
                      )}
                    </select>

                  </div>
                  {formFields && formFields.field.map((field, fieldIndex) => (
                    <div key={fieldIndex} className="form-group mb-3">
                      <label className="font15 mb-0 font500">{field.title}</label><br />
                      {field.subtitle && <small className="font13 font-muted font300">{field.subtitle}</small>}

                      {/* Input Handling */}
                      {field.tag === 'input' && (
                        <>
                          {field.inputType === 'checkbox' ? (
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={field.name}
                                {...register(field.name, { required: !field.optional && field.requiredMsg })}
                              />
                              <label className="form-check-label" htmlFor={field.name}>
                                {field.title}
                              </label>
                            </div>
                          ) : field.inputType === 'radio' ? (
                            field.options.map((option, optionIndex) => (
                              <div className="form-check" key={optionIndex}>
                                <input
                                  required
                                    type={field.inputType}
                                    value={field.class} // Use a unique value from each radio button
                                    style={{ marginRight: '10px' }}
                                    {...register(field.name, {
                                      required: field.optional ? false : field.requiredMsg,
                                      onChange: handleRadioChange,
                                    })}
                                  />
                              </div>
                            ))
                          ) : (
                            <input
                              type={field.inputType}
                              className="form-control"
                              placeholder={field.placeholder}
                              style={{ padding: '15px', border: '1px solid #cfcece', borderRadius: '5px', marginTop: '8px', fontSize: '15px' }}
                              {...register(field.name, {
                                required: !field.optional && field.requiredMsg,
                                // minLength: field.lenMsg ? { value: 10, message: field.lenMsg } : undefined,
                              })}
                            />
                          )}
                         
                        </>
                      )}

                      {/* Textarea Handling */}
                      {field.tag === 'textarea' && (
                        <textarea
                          className="form-control"
                          placeholder={field.placeholder}
                          style={{ padding: '15px', border: '1px solid #cfcece', borderRadius: '5px', marginTop: '8px', fontSize: '15px' }}
                          {...register(field.name, {
                            required: !field.optional && field.requiredMsg,
                            // minLength: field.lenMsg ? { value: 10, message: field.lenMsg } : undefined,
                          })}
                        />
                      )}
                    

                      {/* Select Handling */}
                      {field.tag === 'select' && (
                        <>
                         <select
                          className="form-select"
                          style={{ padding: '15px', border: '1px solid #cfcece', borderRadius: '5px', marginTop: '8px', fontSize: '15px' }}
                          {...register(field.name, {
                            required: !field.optional && field.requiredMsg,
                          })}
                        >
                          {field.options.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.key}>
                              {option.select_value}
                            </option>
                          ))}
                           
                     {errors[field.name] && (
                            <p style={{ color: 'red', fontSize: '12px' }}>
                              {errors[field.name]?.message?.toString() || `${field.title} is required`}
                            </p>
                          )}

                        </select>
                        </>
                       
                        
                      )}

                      {/* File Handling */}
                      {field.inputType === 'file' && (
                        <input
                          type="file"
                          className="form-control"
                          {...register(field.name, { required: !field.optional && field.requiredMsg })}
                          style={{ padding: '15px', border: '1px solid #cfcece', borderRadius: '5px', marginTop: '8px', fontSize: '15px' }}
                        />
                      )}
                     
                     {errors[field.name] && (
                            <p style={{ color: 'red', fontSize: '12px' }}>
                              {errors[field.name]?.message?.toString() || `${field.title} is required`}
                            </p>
                          )}


                    </div>
                  ))}


                  <Button type="submit" className="btn btn-primary primary-filled-button py-2 px-4">Generate Content!</Button>
                </form>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="p-3 bg-light rounded mb-4">
              <div className="d-flex align-items-center mb-2">
                <h5 className="text-center">Output</h5>
                <div className="ms-auto font15 d-flex gap-3">
                 
                  <Link to="#" onClick={handleCopy}>
                    <i className="fa fa-clone"></i>
                  </Link>
                  <Link to="#" onClick={handleThumbsUp}>
                    <i className={`fa ${thumbsUp ? "fa-thumbs-up" : "fa-thumbs-o-up"}`}></i>
                  </Link>
                  <Link to="#" onClick={handleThumbsDown}>
                    <i className={`fa ${thumbsDown ? "fa-thumbs-down" : "fa-thumbs-o-down"}`}></i>
                  </Link>
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
              
                <>
                 {gptResponse ? (
                    <TypingEffect text={gptResponse} speed={10} />
                  ) : (
                    <div>No Output available.</div>
                  )}
                </>
               
               
              )}

              <br /><br />
              <div>
              {gptResponse && (
                <div  className="d-flex justify-content-between">
                  <Button onClick={handleDiscard} className="btn btn-danger danger-filled-button py-2 px-4" type='button'>Discard</Button>
                  {/* <Button onClick={() => setShowAdditionalPromptForm(true)} className="btn btn-primary primary-filled-button py-2 px-4" type='button'>Regenerate</Button> */}
                  <Button onClick={handleSave} className="btn btn-primary primary-filled-button py-2 px-4" type='button'>Save</Button>
                </div>
              )}
              </div>
            </div>

             {/* Additional Prompt Form here  */}


          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default TemplateDetail;





  // const onSubmit = async (data: Record<string, any>) => {
  //   try {
  //     setLoading(true);
  
  //     // Check if id is defined before using it
  //     if (typeof id === 'undefined') {
  //       throw new Error('Template ID is undefined');
  //     }
  
  //     // Convert id to number
  //     const templateId = Number(id);
  //     if (isNaN(templateId)) {
  //       throw new Error('Invalid template ID');
  //     }
  //     setStoredTemplateId(templateId); // Store the template ID
  
  //     // Parse avatar ID and check if it is valid
  //     const selectedAvatarId = parseInt(data.avatar, 10);
  //     if (isNaN(selectedAvatarId)) {
  //       throw new Error('Invalid avatar ID');
  //     }
  //     setStoredAvatarId(selectedAvatarId); // Store the avatar ID
  
  //     // Fetch avatar details
  //     const avatarDetails = await fetchAvatarDetails(selectedAvatarId);
  //     const userResponse = avatarDetails ? avatarDetails.user_response : {};
  
  //     // Check if template and gptPrompt are available
  //     if (!template || !template.gptprompt) {
  //       throw new Error('GPT prompt not found');
  //     }
  //     let gptPrompt = template.gptprompt;

  //     gptPrompt = gptPrompt.replace(/{(.*?)}/g, (match, fieldName) => {
  //       // First check in the userResponse
  //       if (userResponse[fieldName] !== undefined) {
  //         return userResponse[fieldName];
  //       }
      
  //       // If not found, check in formFields
  //       if (formFields && formFields.field) {
  //         // Check if the current field name matches the placeholder
  //         for (const field of formFields.field) {
  //           if (field.name === fieldName) { // Match the field name with the placeholder
  //             const fieldValue = data[field.name];
  //             if (fieldValue !== undefined) {
  //               return fieldValue;
  //             }
  //           }
  //         }
  //       }
      
  //       // Fallback if no value is found
  //       return 'No response';
  //     });
      
      
  
  //     // Build result string
  //     let resultString = `${gptPrompt}\n\n`;
  //     // for (const [key, value] of Object.entries(userResponse)) {
  //     //   resultString += `Question: ${key}:\n Answer: ${value}\n`;
  //     // }
  
  //     // formFields?.field.forEach((field) => {
  //     //   const fieldValue = data[field.name] || 'No response';
  //     //   resultString += `Question: ${field.title}:\n Answer: ${fieldValue}\n`;
  //     // });
  
  //     console.log(resultString);
  
  //     // Make API requests
  //     const headers = {
  //       'Content-Type': 'application/json',
  //     };
  
  //     const gptResponse = await axiosRequest('/chatgpt/analyze', { result_string: resultString }, headers);
  
  //     const historyResponse = await axiosRequest('/admin/userAvatarHistory/create', {
  //       avatar_id: selectedAvatarId,
  //       template_id: templateId,
  //       result_string: gptResponse.response,
  //     }, {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     });
  
  //     console.log('History created:', historyResponse);
  //     setGptResponse(gptResponse.response); // Set the GPT response in state
  //     setLoading(false);
  
  //     const { history_id } = historyResponse;
  //     setHistoryId(history_id);
  //     console.log('History ID:', history_id);
  
  //   } catch (error:any) { 
  //     setLoading(false);
  //     if (error.response && error.response.status === 403) {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Exceeded limit',
  //         text: 'You have exceeded the maximum number of requests. Please try again later.',
  //         customClass: {
  //           confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
  //         },
  //       });
  //     } else {
  //       // Handle other errors
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Error',
  //         text: error.message || 'An unexpected error occurred. Please try again.',
  //         customClass: {
  //           confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
  //         },
  //       });
  //     }
  //   }
  // };
  
  

  // const handleAdditionalPromptSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   setLoading(true);
  
  //   try {
  //     if (storedAvatarId === null || storedTemplateId === null) {
  //       throw new Error('Stored data is missing');
  //     }
  
  //     const selectedAvatarId = storedAvatarId;
  //     const templateId = storedTemplateId;
  
  //     const avatarDetails = await fetchAvatarDetails(selectedAvatarId);
  //     const userResponse = avatarDetails ? avatarDetails.user_response : {};
  
  //     if (!template || !template.gptprompt) {
  //       throw new Error('GPT prompt not found');
  //     }
  //     // const gptPrompt = template.gptprompt;
  
  //     // // Use the formRef to get the form data
  //     // const form = formRef.current; // Referencing the form directly
  //     // if (!form) throw new Error('Form not found');
  
  //     // const formData = new FormData(form);
  
  //     // let resultString = `${gptPrompt}\n\n`;
  //     // for (const [key, value] of Object.entries(userResponse)) {
  //     //   resultString += `Question: ${key}:\n Answer: ${value}\n`;
  //     // }
  
  //     // formFields?.field.forEach((field) => {
  //     //   const fieldValue = formData.get(field.name) as string || 'No response';
  //     //   resultString += `Question: ${field.title}:\n Answer: ${fieldValue}\n`;
  //     // });
  //     let gptPrompt = template.gptprompt;

  //   // Use the formRef to get the form data
  //     const form = formRef.current; // Referencing the form directly
  //     if (!form) throw new Error('Form not found');

  //     const formData = new FormData(form);

  //     // Replace placeholders in the gptPrompt with userResponse or form values
  //     gptPrompt = gptPrompt.replace(/{(.*?)}/g, (match, fieldName) => {
  //       // Check if the key exists in userResponse
  //       if (userResponse.hasOwnProperty(fieldName)) {
  //         return userResponse[fieldName] || 'No response';
  //       }
  //       // If not found in userResponse, get the value from the form data
  //       // const fieldValue = formData.get(fieldName) as string || 'No response';
  //       // console.log(`Field value from form: ${fieldName} -> ${fieldValue}`);
  //       // return fieldValue;
  //       if (formFields && formFields.field) {
  //         for (const field of formFields.field) {
  //           if (field.name === fieldName) { // Match the field name with the placeholder
  //             // Use formData to get the value
  //             const fieldValue = formData.get(field.name) as string || 'No response'; // Change data to formData
  //             console.log(`Field value from form: ${fieldName} -> ${fieldValue}`);
  //             return fieldValue;
  //           }
  //         }
  //       }
        
  //     });

  //     let resultString = `${gptPrompt}\n\n`;
  
  //     if (additionalPrompt) {
  //       resultString += `\nAdditional Prompt:\n${additionalPrompt}\n`;
  //     }
  
  //     console.log(resultString);
  
  //     const headers = {
  //       'Content-Type': 'application/json',
  //     };
  //     const gptResponse = await axiosRequest('/chatgpt/analyze', { result_string: resultString }, headers);
  //     const historyResponse = await axiosRequest('/admin/userAvatarHistory/create', {
  //       avatar_id: selectedAvatarId,
  //       template_id: templateId,
  //       result_string: gptResponse.response,
  //     }, {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     });
  
  //     console.log('History created:', historyResponse);
  //     setGptResponse(gptResponse.response); // Set the GPT response in state
  //     setLoading(false);
  
  //     const { history_id } = historyResponse;
  //     setHistoryId(history_id);
  //     console.log('History ID:', history_id);
  
  //     setShowAdditionalPromptForm(false);
  //     setAdditionalPrompt('');
  
  //   } catch (error:any) {
  //     console.error('Error submitting additional prompt:', error);
  //     setLoading(false);
      
  //   }
  // };






  // {showAdditionalPromptForm && (
  //   <div className="p-3 bg-light rounded mb-4">
  //     {/* <h5 className="text-center">Additional Prompt</h5> */}
  //     <h5 className="text-center">
  //       {template?.title ? `${template.title}` : "Additional Prompt"}
  //     </h5>
  //     <form ref={formRef} onSubmit={handleAdditionalPromptSubmit}>
  //       <div className="form-group mb-3">
  //         <label className="font15 mb-0 font500">Enter Additional Prompt*</label>
  //         <textarea
  //           className="form-control"
  //           placeholder="Type your additional prompt here"
  //           value={additionalPrompt}
  //           onChange={(e) => setAdditionalPrompt(e.target.value)}
  //           rows={4}
  //         />
  //       </div>
  //       <div className="d-flex justify-content-between">
  //       <Button
  //         type="button"
  //         onClick={() => setShowAdditionalPromptForm(false)}
  //         className="btn btn-danger danger-filled-button py-2 px-4"
  //       >
  //         Cancel
  //       </Button>
  //       <Button type="submit" className="btn btn-primary primary-filled-button py-2 px-4">
  //        Regenrate
  //       </Button>
        
  //       </div>
        
  //     </form>
  //   </div>
  // )}