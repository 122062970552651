import React from 'react';
import logo from '../assets/template/images/logo.svg';
import { Link } from 'react-router-dom';

const TermsAndConditions: React.FC = () => {
    return (
        <div style={{
            padding: '40px',
            lineHeight: '1.8',
            color: '#333',
            width: '21cm', // A4 width
            margin: '2rem auto',
            boxSizing: 'border-box',
            pageBreakAfter: 'always',
            border: '1px solid #ccc',
            borderRadius: '10px',
            
        }}>
            <div className="fxt-form-logo">
                <Link to="/"><img src={logo} className="img-fluid" /></Link>
            </div>
            <h4 style={{ textAlign: 'center', marginBottom: '30px', color: '#007BFF' }}>Terms & Conditions</h4>
            
            <p style={{  marginBottom: '20px' }}>
                By accessing and using Funnel Copy Kit, you agree to the following Terms & Conditions:
            </p>

            <section >
                <p style={{ marginBottom: '5px' }}>1. <b>Permitted Use</b></p>
                <p style={{marginLeft: '20px'}}>
                    You may only use Funnel Copy Kit as intended, for lawful purposes, and incompliance with all applicable laws and regulations.
                </p>
                
                <p style={{ marginBottom: '5px' }}>2. <b>Prohibited Use</b></p>
                <p style={{marginLeft: '20px'}}>
                    You may not use Funnel Copy Kit to create content that promotes illegal activities, scams, hate speech, or any form of harm, exploitation, or unethical practices.
                </p>

                <p style={{  marginBottom: '5px' }}>3. <b>Account Security</b></p>
                <p style={{marginLeft: '20px'}}>
                    You are responsible for maintaining the confidentiality and security of your account. Funnel Copy Kit will not be held responsible for unauthorized access due to negligence on your part.
                </p>

                <p style={{ marginBottom: '5px' }}>4. <b>Limitation of Liability</b></p>
                <p style={{marginLeft: '20px'}}>
                    Funnel Copy Kit provides tools and templates to assist in content creation. We are not liable for any outcomes, direct or indirect, resulting from the use of content generated by the software.
                </p>

                <p style={{  marginBottom: '5px' }}>5. <b>Content Responsibility</b></p>
                <p style={{marginLeft: '20px'}}>
                    Users are solely responsible for ensuring that all generated content aligns with their intended use and adheres to ethical and legal standards.
                </p>

                <p style={{  marginBottom: '5px' }}>6. <b>Changes to Terms</b></p>
                <p style={{marginLeft: '20px'}}>
                Funnel Copy Kit reserves the right to update or modify these terms at any time. Continued use of the software constitutes acceptance of the updated terms.
                </p>
            </section>

            <p style={{  marginTop: '30px' }}>
                By using Funnel Copy Kit, you acknowledge that you have read, understood, and agreed to these terms.
            </p>
        </div>
    );
};

export default TermsAndConditions;
