import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, useSignIn } from 'react-auth-kit';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/template/css/style.css';
import logo from '../assets/template/images/logo.svg';
import { Button, Toast } from '@blueprintjs/core';
import '../assets/template/fonts/fonts.css';
import axiosRequest from '../network/axiosRequest';
import { Eye,EyeOff} from 'react-feather';

export const Login = () => {
    let navigate = useNavigate();
    const signIn = useSignIn();
    const [page, setPage] = useState('login');
    const isAuthenticated = useIsAuthenticated();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const [active2fa, setActive2fa] = useState(false);
    const [token, setToken] = useState('');
    const [emailDisable, setEmailDisable] = useState(false);
    const [passwordDisable, setPasswordDisable] = useState(false);
    const [signInDisable, setSignInDisable] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [qrCodeData, setQrCodeData] = useState('');

    useEffect(() => {
        if (isAuthenticated()) {
            navigate('/dashboard');
        }
    }, [isAuthenticated, navigate]);

    const submitSignIn = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (password !== '' && email !== '' && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage('');
            setSignInDisable(true);

            try {
                const res = await axiosRequest('/user/login', { email, password }, {});
                console.log(res);

                if (res.requires_2fa) {
                    setPage('2fa');  // Proceed to 2FA page
                } else {
                    if (signIn({
                        token: res.token,
                        expiresIn: 24894848584,
                        tokenType: "Bearer",
                        authState: { 
                            email, 
                            password, 
                            token: res.token, 
                            active2fa: res.active2fa, 
                            user_id: res.user_id 
                        }
                    })) {
                        navigate('/dashboard');
                        window.location.reload();
                    } else {
                        setErrorMessage('Server error. Please reload the page and try again.');
                    }
                }
            } catch (e) {
                setErrorMessage('Invalid email or password');
            } finally {
                setSignInDisable(false);
            }
        } else {
            setErrorMessage('Please enter a valid email and password.');
        }
    };

    const verify2FA = async (e:any) => {
        e.preventDefault();
        if (token) {
            try {
                const res = await axiosRequest('/admin/2fa', { token, email }, {});
                if (signIn({
                    token: res.data.token,
                    expiresIn: 3600,
                    tokenType: "Bearer",
                    authState: { email }
                })) {
                    navigate('/dashboard');
                    window.location.reload();
                } else {
                    setErrorMessage('Server error. Please reload the page and try again.');
                }
            } catch (e) {
                setErrorMessage('Invalid 2FA token');
            }
        } else {
            setErrorMessage('Please enter the 2FA token');
        }
    };

    useEffect(() => {
        if (page === '2fa') {
            const fetchQrCode = async () => {
                try {
                    const res = await axiosRequest('/admin/2fa/setup', { email }, {});
                    setQrCodeData(res.qr_code_data);
                } catch (e) {
                    setErrorMessage('Failed to load QR code');
                }
            };
            fetchQrCode();
        }
    }, [page]);

    const enableDisableInputs = (bool: boolean | ((prevState: boolean) => boolean)) => {
        setPasswordDisable(bool);
        setEmailDisable(bool);
        setSignInDisable(bool);
    };

    return (
        <div className="authWrapper">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="fxt-form-logo">
                            <img src={logo} className="img-fluid" />
                        </div>
                        {page === 'login' && (
                            <div className="fxt-content">
                                <div className="fxt-form-head text-center">
                                    <h4>Welcome back!</h4>
                                    <p>Please login to your account</p>
                                    {errorMessage && (
                                        <Toast
                                            timeout={500000}
                                            onDismiss={() => setErrorMessage('')}
                                            message={errorMessage}
                                        />
                                    )}
                                </div>
                                <form>
                                    <div className="form-group mb-3">
                                        <label htmlFor="email" className="form-label">Email Address</label>
                                        <input 
                                            type="text" 
                                            id="email" 
                                            className="form-control" 
                                            placeholder="Enter Email Address"  
                                            disabled={emailDisable} 
                                            value={email} 
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <div className="input-group">
                                            <input 
                                                type={showPassword ? 'text' : 'password'} 
                                                className="form-control" 
                                                placeholder="Enter Password" 
                                                id="password" 
                                                style={{ height: '40px', borderRight: '0px'}} 
                                                disabled={passwordDisable} 
                                                value={password} 
                                                onChange={(e) => setPassword(e.target.value)} 
                                            />
                                            <button 
                                                type="button" 
                                                className="input-group-text" 
                                                style={{backgroundColor: 'transparent', borderLeft: 'none'}}
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <EyeOff/> : <Eye/>}
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <Link to='/forgot-password' style={{ textDecoration: 'none', color: '#f05a25' }}>Forgot Password?</Link>
                                    </div>
                                    
                                    <button className="btn btn-primary primary-filled-button w-100 py-2" onClick={submitSignIn} disabled={signInDisable}> Login </button>
                                </form>
                            </div>
                        )}
                        {page === '2fa' && (
                            <div className="fxt-content">
                                <div className="fxt-form-head text-center">
                                    <h4>Two-Factor Authentication</h4>
                                    <p>Enter the code from your authenticator app</p>
                                    {qrCodeData && <img src={`data:image/png;base64,${qrCodeData}`} alt="QR Code" />}
                                </div>
                                <form>
                                    <div className="form-group mb-3">
                                        <label htmlFor="token" className="form-label">2FA Code</label>
                                        <input 
                                            type="text" 
                                            id="token" 
                                            className="form-control" 
                                            placeholder="Enter 2FA Code" 
                                            value={token} 
                                            onChange={(e) => setToken(e.target.value)} 
                                            required 
                                        />
                                    </div>
                                    <button className="btn btn-primary primary-filled-button w-100 py-2" onClick={verify2FA} disabled={signInDisable}>Verify</button>
                                    {errorMessage && <span className="text-danger">{errorMessage}</span>}
                                </form>
                            </div>
                        )}
                        <div className="fxt-form-tc">
                            <p>By signing in you agree to our <Link to='/terms-and-conditions' style={{ textDecoration: 'none', color: '#f05a25' }}>Terms and Conditions</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
